import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostTease from "../components/lists/post-tease"

const BlogPostTemplate = ({
  data: {
    site,
    markdownRemark: post,
    more: { nodes },
  },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`
  console.log("more", nodes)
  return (
    <Layout location={location} dark={true} title={siteTitle}>
      <div>
        <article
          className="blog-post px-4 lg:px-2 md:w-10/12 w-full lg:w-7/12 mx-auto"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2 className={`  mb-12 article-title`} itemProp="headline">
              {post.frontmatter.title}
            </h2>
            <p className="mb-12">{post.frontmatter.date}</p>
          </header>
          <section
            className="article-content mb-12"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />

          {/* <footer>
          <Bio />
        </footer> */}
        </article>
        {nodes && (
          <div className="w-full lg:w-7/12 mx-auto px-4 lg:px-2">
            <h2 className="text-center mb-8 font-bold text-xl">
              MORE ARTICLES
            </h2>
            {nodes.map((post, i) => (
              <PostTease post={post} key={i} />
            ))}
          </div>
        )}
        {/* <nav className="blog-post-nav max-w-full w-7/12 mx-auto mb-24">
          <div className="w-full flex gap-4 justify-between">
            <div>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </div>
            <div>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </div>
          </div>
        </nav> */}
      </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    more: allMarkdownRemark(
      filter: { id: { ne: $id }, frontmatter: { type: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        id
        excerpt(pruneLength: 160)
        html
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
